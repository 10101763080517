.App {
  text-align: center;
}

.red {
  colour: #d14128;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hero-listing {
  background-image: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* .c-preloader {
  display: none;
} */


.c-hero__bg {
  -webkit-transition: background-image 1s ease-in-out !important;
  transition: background-image 1s ease-in-out !important;
  -webkit-backface-visibility: hidden !important;
  -moz-backface-visibility:    hidden !important;
  -ms-backface-visibility:     hidden !important;
  -webkit-transform: translate3d(0, 0, 0);
}

.gallery {
  visibility: hidden;
  position: absolute;
}

.move-down {
  position: absolute;
  top: 150px;
}

.cta-right {
  right: 0;
  left: 90vw;
}

.intro-right {
  display: flex;
  /* flex-flow: row wrap; */
}

.arrow {
  font-size: 100px;
  transition: all 100ms linear;
  color: #fff;
  opacity: .65;
  position: absolute;
  z-index: 100;
}

.arrow:hover {
  cursor: pointer;
  color: #fff;
  opacity: 1 !important;
}

.right-arrow {
  right: 0
}

.left-arrow {
  left: 0
}

.centered {
  text-align: center;
  width: 100%;
}

.filters {
  display: flex;
  flex-flow: row wrap;
}

.filters .block-ctn {
  width: 100%;
}

.type-filter select {
  background: lightgrey;
  margin-left: 10px;
  padding: 10px;
}

.sorting {
  margin-top: 20px;
  display: flex;
}

.type-filter input {
  width: 100%;
  padding: 10px;
}

.filters .block-ctn {
  display: flex;
  flex-flow: row wrap;
}

.all-display-coms .display-filter {
  width: 50%;
  margin-bottom: 20px;
}

.long-filter {
  margin-bottom: 20px;
}

hr.heading {
  margin: 20px 0;
  margin-top: 40px;
}

.o-h3-black {
  color: black;
  text-transform: capitalize;
}

.c-img-txt__img.reversed-image {
  order: 4;
}

.contact-form {
  width: 100%;
  text-align: center;
}

.contact-form .inputs input,
.contact-form .inputs textarea {
  min-width: 50%;
  margin-bottom: 20px;
  padding: 10px;
}

.contact-form .inputs .label {
  margin: 10px 0;
}

.btn:hover {
  cursor: pointer;
}

.centered {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  border: 1px solid black;
  padding: 5px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-hero__title {
  text-shadow: 0px 0px #000;
}

em.small-text div, em.small-text {
  text-transform: lowercase;
  display: inline-block !important;
  font-weight: normal;
  margin-right: 3px;
}

.flex.justify-space {
  display: flex;
  justify-content: space-around;
}

.right-text.paging div {
  width: 100%;
}

.align-right {
  text-align: right;
}

.right-text.paging {
  display: flex;
  flex-flow: row wrap;
}

.all-display-coms {
  margin-top: 10px;
}
